<template>
  <div id="feedbackHistory">
    <section class="nav-bar">
      <div class="icon go_leftIcon" @click="jumpBack"></div>
      <div class="nav-bar__title">反馈历史</div>
    </section>
    <!-- <div class="bottomTip">
      <i class="informationIcon"></i>
      <div>反馈信息</div>
    </div> -->
    <div
      class="feedbackContent"
      v-for="(item, index) in data"
      :key="index"
      @click="details(item.exId)"
    >
      <i class="feedbackIcon"></i>
      <div class="contentTitle">反馈内容</div>
      <div class="contentInformation">{{ item.exContent }}</div>
      <div class="contentTime">
        <span class="date">{{ timestampConversion(item.exDate) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { feedbackEngineerExList } from "../api/home";
export default {
  data() {
    return {
      userId: this.$route.query.userId,
      orderNo: this.$route.query.orderNo,
      data: {},
    };
  },
  mounted() {
    this.getFeedbackEngineerExList();
  },
  methods: {
    getFeedbackEngineerExList() {
      let obj = {
        userid: this.userId,
        orderNo: this.orderNo,
      };
      feedbackEngineerExList(obj).then((res) => {
        if (res.status) {
          this.data = res.result;
        }
      });
    },
    // 时间戳转换
    timestampConversion(data) {
      var timestamp = new Date(data);
      return (
        timestamp.toLocaleDateString().replace(/\//g, "-") +
        " " +
        timestamp.toTimeString().substr(0, 8)
      );
    },
    //查看详情
    details(exId) {
      this.$router.push({
        path: "/feedbackDetails",
        query: {
          userId: this.userId,
          exId: exId,
        },
      });
    },
    // 跳转返回
    jumpBack() {
      window.history.go(-1);
    },
  },
  beforeCreate() {
    // 修改背景色
    document
      .querySelector("body")
      .setAttribute("style", "background-color:#f4f4f4");
  },
  beforeDestroy() {
    // 销毁背景色
    document.querySelector("body").removeAttribute("style");
  },
};
</script>

<style lang="less" scoped>
#feedbackHistory {
  background: #f4f4f4;
  .nav-bar {
    position: relative;
    width: 750px;
    height: 79px;
    background: #ffffff;
    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.06);

    .go_leftIcon {
      position: absolute;
      width: 17px;
      height: 30px;
      left: 25px;
      top: 25px;
      background: url("../assets/images/pending/go_left.png") no-repeat;
      background-size: 100%;
    }

    .nav-bar__title {
      font-size: 32px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #111111;
      text-align: center;
      line-height: 79px;
    }
  }

  .feedbackContent {
    position: relative;
    width: 750px;
    height: 124px;
    background: #ffffff;
    margin: 10px 0;
    text-align: left;

    .feedbackIcon {
      position: absolute;
      top: 28px;
      left: 30px;
      width: 60px;
      height: 60px;
      background: url("../assets/images/pending/feedbackIcon.png") no-repeat;
      background-size: 100%;
    }

    .contentTitle {
      display: block;
      font-size: 26px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 26px;
      padding-top: 27px;
      margin-left: 112px;
    }

    .contentInformation {
      display: block;
      line-height: 24px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #808080;
      margin-top: 18px;
      margin-left: 112px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .contentTime {
      position: absolute;
      top: 33px;
      right: 32px;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #bfbfbf;
      line-height: 24px;

      .time {
        margin-left: 9px;
      }
    }
  }

  .feedbackContent:last-child {
    margin-bottom: 100px;
  }

  .bottomTip {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 100;
    width: 750px;
    height: 90px;
    background: #ffffff;
    font-size: 28px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #4d4d4d;
    text-align: center;
    line-height: 90px;

    .informationIcon {
      display: inline-block;
      width: 36px;
      height: 36px;
      background: url("../assets/images/pending/informationIcon.png") no-repeat;
      background-size: 100%;
      vertical-align: middle;
      margin-right: 10px;
    }

    div {
      display: inline-block;
    }
  }
}
</style>